<template>
  <div class="row mx-0">
    <div class="col-11 col-lg-10 mx-auto px-0">
      <div class="card p-3">
        <h3 class="text-center ">Todos os Usuário</h3>
        <table class="d-none d-md-table table m-0">
          <thead>
            <tr>
              <th class="text-center" style="width:auto;">
                <b-icon icon="gear-fill" style="margin:0 9.5px;" />
              </th>
              <th v-for="(h,hx) in thead" :key="`header-${hx}`" style="width:32%;"> {{ h }} </th>
            </tr>
          </thead>
        </table>
        <div id="section-content" class="content">
          <table v-if="users.count" class="table m-0 table-hover table-info align-middle">
            <tbody>
              <tr v-for="(u, ux) in users.rows" :key="`users-${ux}`">
                <td class="text-center" style="width:5%;">
                  <b-dropdown dropright size="sm" variant="secundary" no-caret>
                    <template #button-content>
                      <b-icon icon="gear-fill" animation="spin" />
                    </template>
                    <b-dropdown-item @click="editUser(u)">
                      <b-icon icon="pencil-square" variant="info" />
                      <span class="px-2 text-info">Editar</span> </b-dropdown-item>
                    <b-dropdown-divider />
                    <b-dropdown-item @click="setStatus(u.id)">
                      <b-icon :icon="`patch-${!u.user_status?'check':'minus'}-fill`"
                        :variant="!u.user_status?'success':'danger'" />
                      <span class="px-2" :class="`text-${!u.user_status?'success':'danger'}`">
                        {{ (u.user_status) ? 'Desativar' : 'Ativar' }}</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </td>
                <td> {{ u.display_name }} </td>
                <td> @{{ u.user_login }} </td>
                <td :class="`text-${u.user_status?'success':'danger'}`" class="small">
                  <div class="d-flex align-items-center">
                    <b-icon :icon="`${u.user_status?'check-circle-fill':'slash-circle-fill'}`" class="mx-2" />
                    {{ u.user_status ? 'Ativo':'Desativado' }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <p v-else-if="!loading" class="text-center ">Nenhum registro encontrado!</p>
          <b-icon v-else icon="circle-fill" animation="throb" font-scale="4" class="w-100 my-3 my-lg-5 mx-auto" />
        </div>
        <div v-if="users.count" class="row mx-0 my-2 align-items-center justify-content-end">
          <div class="col-auto"> {{ `${first} - ${end} de ${users.count || 0}` }} </div>
          <div class="col-auto">
            <b-pagination v-model="pgt.page" @change="getUsers($event)" :per-page="pgt.limit" :total-rows="users.count"
              align="right" class="m-0" />
          </div>
        </div>
        <div class="row mx-0 justify-content-around">
          <button @click="$router.push('/noticias/inicio')" class="col-auto my-3 btn btn-default border">Voltar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        loading: false,
        thead: ['Nome', 'Usuário', 'Status'],
        pgt: {
          page: 1,
          limit: 5,
          offset: 0,
        },
      }
    },
    computed: {
      users() {
        return this.$store.state.news.users;
      },
      first() {
        return (this.pgt.offset + 1);
      },
      end() {
        return Math.min(this.pgt.page * this.pgt.limit, this.users.count);
      }
    },
    beforeMount() {
      this.$store.commit('EDIT_USERS', {})
    },
    mounted() {
      this.pgt.limit = Math.floor(document.querySelector('#section-content').offsetHeight / 50)
      this.getUsers();
    },
    methods: {
      getUsers(payload) {
        this.loading = true
        this.pgt.offset = parseInt(this.pgt.limit * (payload - 1) || 0);
        const query = `limit=${this.pgt.limit}&offset=${this.pgt.offset}`
        this.$store.dispatch('getUsers', query)
          .catch((error) => {
            this.swal(false, error.data.mensagem, error);
            if (error.status == 401) this.$emit('Auth');
          })
          .finally(() => this.loading = false)
      },
      editUser(payload) {
        this.$store.commit('EDIT_USERS', payload)
        this.$router.push('/noticias/usuario/editar')
      },
      setStatus(payload) {
        this.$store.dispatch('setStatus', payload)
          .then((res) => [this.swal(true, res.mensagem)])
          .catch((error) => {
            this.swal(false, error.data.mensagem, error);
            if (error.status == 401) this.$emit('Auth');
          })
          .finally(() => this.getUsers());
      }
    }
  }
</script>

<style scoped>
  .content {
    height: calc(100vh - 400px);
    overflow: auto;
  }
</style>